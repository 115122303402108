







import { defineComponent } from '@vue/composition-api';
import DefaultLayout from '@/layouts/default.vue';
import ContainerTask from '@/components/containers/ContainerTask.vue';
export default defineComponent({
	components: {
		DefaultLayout,
		ContainerTask,
	},
	created() {
		this.$store.commit('SET_BREADCRUMB', {
			viewTxt: 'Giao việc',
			ableToBack: false,
			rootRouter: '/',
			hasStatus: false,
			statusTxt: '',
		});
		this.$store.commit('SET_ADDLIB', false);
		this.$store.commit('SET_ADDLIBACTION', () => {
			console.log('nth');
		});
	},
	methods: {},
});
