














import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import api from "@/services";
import TableApproval from "@/components/Table/TableApproval.vue";
import ConfirmRemove from "@/components/popup/ConfirmRemove.vue";
import ConfirmCancel from "@/components/popup/ConfirmCancel.vue";
import { SharedPagination } from "@/components/Shared";
import { NormalPagination } from "@/InterfaceModel/Pagination";
import { NormalHeaderItem } from "@/InterfaceModel/Header";
import { IdentifyObject } from "@/InterfaceModel/CustomObject";
import useRouteQuery from "@/utils/uses/routerQuery/useRouteQuery";
import { TextFilter } from "@/components/Shared";
import route from "@/router/index";
import { mapState } from "vuex";
import { filter } from "vue/types/umd";
export default defineComponent({
  components: {},

  computed: {
    ...mapState({
      previousPagination: (state: any) => state.previousPagination,
    }),
  },
  methods: {},
});
